<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondAlertsTab.vue
Description:this file is the combination of  PondAlertsTable and DeviceWiseAlerts
-->
<template>
  <el-row class="pond-alerts-tab" slot="layout-pondlogs-scrollable-main">
    <el-row
      v-if="!isHistoryAlertActive"
      type="flex"
      :gutter="10"
      class="dashboard-row-1"
    >
      <el-col class="table-container" :span="16">
        <el-row class="alerts-table" type="block">
          <PondAlertsTable
            @refresh="initComponent"
            @history="handleHistoryClick"
            :loading="loading ? loading : tableLoading"
          />
        </el-row>
      </el-col>
      <el-col :span="8">
        <el-row v-show="!loading">
          <div class="pond-alerts-count">
            <p class="pond-alerts-count__title">
              {{ $t("total_ponds_with_alerts") }}
            </p>
            <p class="pond-alerts-count__value">
              {{ (totalPondsWithAlerts || []).length }}
            </p>
          </div>
        </el-row>

        <el-row
          class="device-alerts"
          type="block"
          v-loading="loading"
          element-loading-background="white"
        >
          <DeviceWiseAlerts
            :loading="loading"
            @alert-item="handleAlertItemClick"
          />
        </el-row>
      </el-col>
    </el-row>
    <el-row v-if="isHistoryAlertActive">
      <el-col :span="24">
        <el-row class="alerts-table" type="block">
          <history-alert-table
            @refresh="initComponent"
            @history="handleHistoryClick"
            :loading="loading ? loading : tableLoading"
            :tabData= tabData
          />
        </el-row>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DeviceWiseAlerts from "./deviceWiseAlerts";
import PondAlertsTable from "./pondAlertsTable";
import HistoryAlertTable from "./HistoryAlertTable.vue";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import autoRefreshMixin from "@/mixins/autoRefreshMixin.js";
import pondmother from "@/store/components/pondmother";
import featureToggleMixin from "@/mixins/featureToggleMixin";
export default {
  name: "PondAlertsTab",
  mixins: [errorHandlerMixin, autoRefreshMixin, featureToggleMixin],
  provide: function() {
    return {
      pondAlertTabData: this.$data
    };
  },
  props: ['tabData'],
  data: function() {
    return {
      loading: true,
      tableLoading: false,
      isHistoryAlertActive: JSON.parse(localStorage.getItem("AlertHistory"))
        ? JSON.parse(localStorage.getItem("AlertHistory"))
        : false,
      query: {
        location_id: null,
        biomass_units: "KG",
        include: ["title", "pond_mothers", "shrimp_talks", "pond_guards"],
        get_all: true,
        status: ["ACTIVE"],
        not_include_test_ponds: true
      }
    };
  },
  components: {
    DeviceWiseAlerts,
    PondAlertsTable,
    HistoryAlertTable
  },
  created() {
    if (!this.$store.hasModule("pondmother")) {
      this.$store.registerModule("pondmother", pondmother);
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$store.hasModule("pondmother")) {
      this.$store.dispatch("CANCEL_PENDING_REQUESTS");
      this.$store.unregisterModule("pondmother");
    }
    next();
  },
  async mounted() {
    await this.fetchUserDevicesCountData({ user_id: this.getUserId });
    await this.initComponent();
    if (this.ftgm__isFeatureAvailable(this.featureToggleMetaData)) {
      this.arm__activateTimer(this.initComponent, 10);
    }
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
      getUserTimeZone: "getUserTimeZone",
      getUserId: "getUserId"
    }),
    ...mapGetters("pondmother", {
      getObjPmIdPm: "getObjPmIdPm"
    }),
    ...mapGetters("pond", {
      ponds: "getActivePonds"
    }),
    ...mapGetters("alertsInfo", {
      alertsFilter: "getAlertsFilter",
      getValidErrorAlerts: "getValidErrorAlerts",
      getPondsHavingAlerts: "getPondsHavingAlerts",
      getTotalDeviceWiseAlertsCount: "getTotalDeviceWiseAlertsCount"
    }),
    totalPondsWithAlerts() {
      return this.getPondsHavingAlerts.filter(el => el.title !== undefined);
    },
    featureToggleMetaData() {
      return {
        currUser: this.getUserProfile,
        featureAllowedUsers: [
          "5e565a0cbc2c4dd8b5f6efd0",
          "5f47724756751a1b6d427dd2", // eruvaka.test
          "5e5cfb322c599d13d25b607e" // pond.owner1 dev
        ]
      };
    },
    getTableData() {
      return Object.values(this.errorAlertDeviceMap).flat(1);
    }
  },
  methods: {
    ...mapActions("pondmother", {
      fetchAllPondMothers: "fetchAllPondMothers"
    }),
    ...mapActions("pond", {
      fetchAllPonds: "fetchAllPonds"
    }),
    ...mapActions("user", {
      fetchUserDevicesCountData: "fetchUserDevicesCountData"
    }),
    ...mapActions("alertsInfo", {
      fetchAllAlerts: "fetchAllAlerts"
    }),
    async handleHistoryClick(event) {
      // this.query.isHistoryAlertActive = !this.query.isHistoryAlertActive;
      this.loading = true;
      await this.initComponent();
      console.log("event", event);
      localStorage.setItem("AlertHistory", event);
      this.isHistoryAlertActive = event;
    },
    async initComponent() {
      this.loading = true;
      this.query.location_id = this.getCurrUserLocation._id;
      try {
        await this.fetchAllAlerts();
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async handleAlertItemClick(alertData) {
      this.tableLoading = true;
      setTimeout(() => {
        this.tableLoading = false;
      }, 500);
    }
  },
  destroyed() {
    // localStorage.setItem("AlertHistory", false);
  }
};
</script>

<style lang="scss">
.pond-alerts-tab {
  @mixin component-font-size($offset_value: 0) {
    @include responsiveProperty(
      font-size,
      $app_font_size_1 + $offset_value,
      $app_font_size_2 + $offset_value,
      $app_font_size_3 + $offset_value
    );
  }
  .er-card {
    border-radius: 7px;
  }
  .er-list-filter .material-icons-outlined {
    @include icon-size(icon-font);
  }
  .pond-alerts-count {
    display: flex;
    align-items: center;
    height: 2 * $app_font_size_small;
    padding: 8px 20px 8px 10px;
    background: white;
    align-items: center;
    justify-content: space-between;
    border-radius: 7px;
    color: #333333;
    &__title {
      opacity: 0.9;
      color: #0a2463;
      @include responsiveProperty(
        font-size,
        $app_font_size_2,
        $app_font_size_3,
        $app_font_size_xl
      );
    }
    &__value {
      padding-left: 10px;
      border-left: 1px solid #dfebf5;
      color: #e31a1c;
      @include responsiveProperty(
        font-size,
        $app_font_size_2,
        $app_font_size_3,
        $app_font_size_xl
      );
    }
  }
  .dashboard-row-1 {
    overflow: hidden;
    position: relative;
  }
  .el-card {
    border: 0px solid #ebeef5;
  }
  .btns {
    display: flex;
    justify-content: space-between;
    .el-input__inner {
      background: #349aff;
      color: white;
    }
    margin-bottom: 15px;
  }
  .total-alerts {
    margin-bottom: 15px;
  }
}
</style>
