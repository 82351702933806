var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('er-card',{staticClass:"pond-alerts-table",attrs:{"shadow":"never"}},[_c('template',{slot:"header"},[_c('layout-toolbar',{staticClass:"pond__header",attrs:{"gap":"5"}},[_c('p',{staticClass:"pond-alerts-table__header card-title"},[_vm._v("\n        "+_vm._s(_vm.$t("Comn_ponds"))+" "+_vm._s(_vm.$t("Comn_alerts"))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"filler"}),_vm._v(" "),_c('er-button',{class:{
          'no-padding': true,
          'action-icon': true,
          'history-button': !_vm.isHistoryAlertActive,
          'history-button-active': _vm.isHistoryAlertActive
        },attrs:{"btnType":"science","icon":"el-icon-back","type":"text","showIcon":false},on:{"click":_vm.handleHistory}},[_vm._v("History")]),_vm._v(" "),_c('er-date-picker',{attrs:{"size":"mini","timeZoneString":_vm.getUserTimeZoneString,"popper-class":"card-chart__date-picker","value":_vm.selectedDate,"format":_vm.upm__getFormatDateString,"value-format":_vm.datePickerFormat,"arrShortcuts":_vm.arrDatePickerShortCuts},on:{"input":_vm.handleChangeInDateRange}}),_vm._v(" "),_c('er-button',{staticClass:"no-padding action-icon",attrs:{"btnType":"refresh","type":"text","showIcon":true},on:{"click":function($event){return _vm.initComponent(_vm.queryParams, 'refresh')}}}),_vm._v(" "),_c('div',{on:{"click":_vm.handleOpenDialog}},[_c('span',{staticClass:"material-icons-outlined action-icon"},[_vm._v(" queue ")])]),_vm._v(" "),_c('er-dropdown',{staticClass:"highlight",attrs:{"placement":"bottom","trigger":"click","listOfItems":['Comn_download_as_excel', 'Comn_download_as_pdf']},on:{"item-click":_vm.handleTableDataDownload},scopedSlots:_vm._u([{key:"item-title",fn:function(ref){
        var item = ref.item;
return [_vm._v(_vm._s(_vm.$t(item.value)))]}}])},[_c('template',{staticClass:"el-dropdown-link download_button",slot:"suffix"},[_c('span',{staticClass:"material-icons-outlined action-icon"},[_vm._v("\n            file_download\n          ")])])],2)],1),_vm._v(" "),_c('div',{staticClass:"divider"})],1),_vm._v(" "),_c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[(_vm.loading)?_c('Loader'):_vm._e(),_vm._v(" "),_c('er-data-table-server',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],key:_vm.$i18n.locale,ref:"table",attrs:{"unique-id":"pond-alerts-table__table","merge":{ startColumnNumber: 1, endColumnNumber: 3 },"tableData":_vm.tableDataComputed,"columns":_vm.tableColumns,"page-size":100,"pagination-props":_vm.$constants.table['pagination-props'],"total":_vm.totalRecords,"disable-check":false,"tableProps":_vm.getTableProps,"action-column":false,"type":"white-header-table"},on:{"loadData":_vm.initComponent},scopedSlots:_vm._u([{key:"pond",fn:function(ref){
        var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"pond","withoutHighlightStrings":row.data.pond_name}})]}},{key:"device_type",fn:function(ref){
        var row = ref.row;
return [_vm._v("\n        "+_vm._s(_vm.$tc(_vm.$constants.deviceTypes[row.data.device_type].lang_key, 1))+"\n      ")]}},{key:"device_id",fn:function(ref){
        var row = ref.row;
return [_c('span',{attrs:{"title":row.data.device_code}},[[_vm._v("\n            "+_vm._s(_vm.upm__getValue(row.data))+"\n          ")]],2)]}},{key:"status",fn:function(ref){
        var row = ref.row;
return [_c('el-tooltip',{attrs:{"content":_vm.renderAlertName(row.data.error_code),"placement":"bottom","popperClass":"pond-alerts-table-el-tooltip__popper","effect":"light"}},[_c('img',{attrs:{"rel":"prerender","src":_vm.renderAlertIcon(row.data.error_code)}})])]}},{key:"duration",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.durationHandle(row.data, 'table')))])]}},{key:"utc_start_date",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(row.data, "utc_start_date")))])]}},{key:"utc_end_date",fn:function(ref){
        var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(row.data, "utc_end_date")))])]}},{key:"voltage",fn:function(ref){
        var row = ref.row;
return [_c('span',{style:(_vm.generateColorForBatterVoltage(row.data))},[_c('span',[_vm._v(_vm._s(_vm.ftm__formatEmpty(_vm.ftm__digitPrecision(_vm.batteryVoltage(row.data)))))])])]}},{key:"resolved_by",fn:function(ref){
        var row = ref.row;
return [_c('er-select',{staticClass:"dropdown-style",attrs:{"size":"mini","width":"220px","clearable":true,"value-key":"code","autocomplete":"new-country","disabled":_vm.disabled,"filterable":""},on:{"change":function($event){return _vm.handleResolvedChange(row.data, 'resolved_by')}},model:{value:(row.data.resolved_by),callback:function ($$v) {_vm.$set(row.data, "resolved_by", $$v)},expression:"row.data.resolved_by"}},_vm._l((_vm.subUsersData || []),function(user,index){return _c('er-option',{key:index,attrs:{"label":_vm.ftm__capitalize(user.name),"value":user.value,"disabled":user.disabled}})}),1)]}},{key:"user_timezone_resolve_date",fn:function(ref){
        var row = ref.row;
return [_c('el-date-picker',{staticClass:"date-picker-style",attrs:{"size":"mini","width":"10%","clearable":true,"type":"datetime","format":"dd-MMM-yy HH:mm","value-format":"yyyy-MM-dd[T]HH:mm:ss.SSS[Z]","popper-class":"date-picker","picker-options":_vm.fromDatePickerOptions},on:{"change":function($event){return _vm.handleResolvedChange(row.data, 'resolved_date')}},model:{value:(row.data.user_timezone_resolve_date),callback:function ($$v) {_vm.$set(row.data, "user_timezone_resolve_date", $$v)},expression:"row.data.user_timezone_resolve_date"}})]}},{key:"resolved_notes",fn:function(ref){
        var row = ref.row;
return [_c('el-input',{attrs:{"size":"mini","type":"textarea","maxlength":"250","autosize":{ minRows: 1, maxRows: 2 },"rows":2},on:{"input":function($event){return _vm.handleResolvedChange(row.data, 'resolved_notes')}},model:{value:(row.data.resolved_notes),callback:function ($$v) {_vm.$set(row.data, "resolved_notes", $$v)},expression:"row.data.resolved_notes"}})]}}])})],1),_vm._v(" "),_c('div',[_c('er-button',{staticClass:"btn-css",attrs:{"btnType":"save","size":"mini","showLabel":true,"showIcon":true,"loading":_vm.saveLoading},on:{"click":_vm.submitUpdatedDetails}}),_vm._v(" "),_c('er-button',{staticClass:"cancel-btn-css",attrs:{"btnType":"cancel","size":"mini","showLabel":true},on:{"click":function($event){return _vm.initComponent(_vm.queryParams, 'cancel')}}})],1),_vm._v(" "),_c("HistoryAlertsActionDialog",{tag:"component",attrs:{"showDialog":_vm.dialogVisible,"tabData":_vm.tabData},on:{"close_dialog":_vm.closeDialog}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }