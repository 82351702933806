<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondAlertsTable.vue
Description:this file gives the info about the alert for devices and its battery levels in the ponds
-->
<template>
  <er-card class="pond-alerts-table" shadow="never">
    <template slot="header">
      <layout-toolbar class="pond__header" gap="5">
        <p class="pond-alerts-table__header card-title">
          {{ $t("Comn_ponds") }} {{ $t("Comn_alerts") }}
        </p>
        <div class="filler"></div>
        <er-button
          :class="{
            'no-padding': true,
            'action-icon': true,
            'history-button': !isHistoryAlertActive,
            'history-button-active': isHistoryAlertActive
          }"
          btnType="primary"
          type="text"
          :showIcon="false"
          @click="handleHistory"
          >History</er-button
        >
        <er-button
          class="no-padding action-icon"
          btnType="refresh"
          type="text"
          :showIcon="true"
          @click="handelReload"
        ></er-button>
        <er-dropdown
          placement="bottom"
          @item-click="handleTableDataDownload"
          trigger="click"
          class="highlight"
          :listOfItems="['Comn_download_as_excel', 'Comn_download_as_pdf']"
        >
          <template class="el-dropdown-link download_button" slot="suffix">
            <span class="material-icons-outlined action-icon">
              file_download
            </span>
          </template>
          <template v-slot:item-title="{ item }">{{ $t(item.value) }}</template>
        </er-dropdown>
        <er-list-filter
          :data="arrPondsForSelection"
          value-key="pond_id"
          class="highlight"
          search-on-key="label"
          :defaultCheckedKeys="getAvailablePondIds"
          @check-change="handleChangeInSelectedPonds"
        >
          <template v-slot:label="{ item }">{{ item.label }}</template>
        </er-list-filter>
      </layout-toolbar>
      <div class="divider" />
    </template>
    <el-row v-loading="loading" element-loading-background="white">
      <Loader v-if="loading"></Loader>
      <er-data-tables
        ref="table"
        v-show="!loading"
        unique-id="pond-alerts-table__table"
        :key="$i18n.locale"
        layout="table"
        :merge="{ startColumnNumber: 1, endColumnNumber: 3 }"
        :tableData="getFilterTableData"
        :sortMethodForColumns="sortMethodForColumns"
        :columns="tableColumns"
        :total="total"
        :disable-check="false"
        :el-table-props="getTableProps"
        :action-column="false"
        type="white-header-table"
      >
        <template v-slot:pond="{ row }">
          <er-highlight-search-component
            :highlightStrings="row.data.highlightStrings"
            :individualRowData="row.data"
            keyName="pond"
            :withoutHighlightStrings="row.data.pond_name"
          />
        </template>

        <template v-slot:device_type="{ row }">
          {{ $tc($constants.deviceTypes[row.data.device_type].lang_key, 1) }}
        </template>
        <template v-slot:device_id="{ row }">
          <span :title="row.data.device_code">
            <template>
              {{ upm__getValue(row.data) }}
            </template>
          </span>
        </template>
        <template v-slot:status="{ row }">
          <!-- <template v-for="(alert,index) in row.data.arr_error_alerts"> -->
          <el-tooltip
            :content="renderAlertName(row.data.error_code)"
            placement="bottom"
            popperClass="pond-alerts-table-el-tooltip__popper"
            effect="light"
          >
            <img rel="prerender" :src="renderAlertIcon(row.data.error_code)" />
          </el-tooltip>
          <!-- </template> -->
        </template>
        <template v-slot:time="{ row }">
          <span>{{ formatDate(row.data) }}</span>
        </template>
        <template v-slot:voltage="{ row }">
          <span :style="generateColorForBatterVoltage(row.data)">
            <span>{{ ftm__formatEmpty(ftm__digitPrecision(batteryVoltage(row.data))) }}</span>
          </span>
        </template>
      </er-data-tables>
    </el-row>
  </er-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import errorAlertsUtils from "@/utils/errorAlertsUtils";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import Loader from "@/components/base/Loader";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin.js";
import exportFileDownload from "@/mixins/exportFileDownload";

export default {
  mixins: [
    errorHandlerMixin,
    userPreferenceMixin,
    datesHandlerMixin,
    filtersMixin,
    exportFileDownload
  ],
  name: "PondAlertsTable",
  props: {
    data: Array,
    loading: Boolean
  },
  components: {
    Loader
  },
  data: function() {
    return {
      selected_pm: {},
      selectedpond: [],
      selectedAlertTypes: [],
      pmHistory: "",
      total: 0,
      query: {
        limit: 30,
        page: 1
      },
      isHistoryAlertActive: false
    };
  },
  computed: {
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits",
      getUserTimeZoneString: "getUserTimeZoneString",
      getCurrUserLocation: "getCurrUserLocation"
    }),
    ...mapGetters("pondmother", {
      getObjPmIdPm: "getObjPmIdPm"
    }),
    ...mapGetters("alertsInfo", {
      getFilterTableData: "getFilterTableData",
      getPondsHavingAlerts: "getPondsHavingAlerts"
    }),

    getLocaleObj: function() {
      return { locale: this.$i18n.locale };
    },
    getTableProps() {
      return {
        border: false,
        stripe: false,
        size: "small",
        height: "calc(100vh - var(--height-gutter))",
        defaultSort: {
          prop: "pond_name",
          order: "ascending"
        }
      };
    },
    getPondsHavingAlertsSortByTitle() {
      return this.$lodash
        .cloneDeep(this.getPondsHavingAlerts || [])
        .sort((a, b) =>
          this.$commonUtils.alphaNumericComparator(a.title, b.title)
        );
    },
    generateColorForBatterVoltage() {
      return (data) => {
        const bv = this.batteryVoltage(data);
        if (!bv) return "#00000";
        if (data.device_type === this.$constants.deviceTypes.POND_GUARD.key) {
          if (bv <= 3.3) {
            return { color: "Red" };
          } else if (bv > 3.3 && bv <= 3.7) {
            return { color: "Orange" };
          } else if (isNaN(bv)) {
            return { color: "Black" };
          } else {
            return { color: "Green" };
          }
        } else if (
          [
            this.$constants.deviceTypes.POND_MOTHER.key,
            this.$constants.deviceTypes.SHRIMP_TALK.key,
            this.$constants.deviceTypes.FEED_BLOWER.key
          ].includes(data.device_type)
        ) {
          if (bv <= 11) {
            return { color: "Red" };
          } else if (bv > 11 && bv <= 12) {
            return { color: "Orange" };
          } else if (isNaN(bv)) {
            return { color: "Black" };
          } else {
            return { color: "Green" };
          }
        }
      };
    },
    pondsForSelection() {
      const ponds = this.getPondsHavingAlertsSortByTitle.reduce((acc, x) => {
        acc[x._id] = { pond_id: x._id, label: x.title, default: true };
        return acc;
      }, {});
      return ponds;
    },
    getAvailablePondIds() {
      return Object.keys(this.pondsForSelection);
    },
    arrPondsForSelection() {
      return Object.values(this.pondsForSelection);
    },
    devicePreference: function() {
      return {
        device_code: {
          label: this.$t("Comn_device_id"),
          sort_key: "sort_device_code"
        },
        device_title: {
          label: this.$t("device_name"),
          sort_key: "sort_device_title"
        }
      };
    },

    downloadColumns() {
      return [
        {
          header: this.tableColumns.pond.label,
          dataKey: "pond_name",
          emptyValue: "--",
          name: "pond",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header: this.tableColumns.device_type.label,
          dataKey: (value) => {
            return this.$tc(
              this.$constants.deviceTypes[value.device_type].lang_key,
              1
            );
          },
          emptyValue: "--",
          name: "device_type",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header: this.tableColumns.device_id.label,
          dataKey: (value) => {
            return this.upm__getValue(value);
          },
          emptyValue: "--",
          name: "device_id",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header: this.tableColumns.voltage.label,
          dataKey: "battery_voltage",
          emptyValue: "--",
          name: "voltage",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header: this.tableColumns.status.label,
          dataKey: (value) => {
            return this.renderAlertName(value.error_code);
          },
          emptyValue: "--",
          name: "status",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        },
        {
          header: this.tableColumns.time.label,
          dataKey: (value) => {
            return this.formatDate(value);
          },
          emptyValue: "--",
          name: "time",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 10
        }
      ];
    },

    tableColumns() {
      return {
        pond: {
          label: this.$tc("Comn_pond", 0),
          prop: "pond_name",
          sortable: "custom"
        },
        device_type: {
          label: this.$t("Comn_device_type"),
          prop: "device_type",
          sortable: true,
          minWidth: 120
        },
        device_id: {
          label: this.devicePreference[this.upm__getPrefDeviceIdentityKey]
            .label,
          prop: this.devicePreference[this.upm__getPrefDeviceIdentityKey]
            .sort_key,
          sortable: "custom",
          minWidth: 120
        },
        voltage: {
          label: this.$t("Comn_voltage"),
          prop: "battery_voltage",
          sortable: true,
          minWidth: 120
        },
        status: {
          label: this.$t("Comn_alert"),
          prop: "user_priority",
          width: 90,
          sortable: true
        },
        time: {
          label: this.$t("Comn_date_and_time"),
          prop: "date",
          minWidth: 120,
          sortable: true,
          sortMethod: (a, b) => {
            return this.dhm__dateUtilsLib.dateComparator(a.date, b.date, true);
          }
        }
      };
    },
    sortMethodForColumns: function() {
      return {
        pond_name: this.$commonUtils.alphaNumericComparator,
        user_priority: (a, b) => a - b,
        sort_device_title: this.$commonUtils.alphaNumericComparator,
        sort_device_code: this.$commonUtils.alphaNumericComparator
      };
    },
    formatDate() {
      return (pond) => {
        if (pond.error_code === "NO_SCHEDULES") return "--";
        return this.dhm__dateUtilsLib.getFormatByTimeZoneOnUTC(
          pond.date,
          this.upm__getFormatDateAndTimeString,
          this.getUserTimeZoneString,
          this.getLocaleObj
        );
      };
    },

    batteryVoltage() {
      return (data) => {
        return data.battery_voltage;
      };
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.table) {
        this.$refs.table.$refs.dataTables.$refs.elTable.doLayout();
      }
    });
  },
  methods: {
    ...mapActions("alertsInfo", {
      changeAlertsFilterSelection: "changeAlertsFilterSelection"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handleHistory() {
      // this.$router.push({
      //   name: "alerts-history"
      // });
      this.mixPanelEventGenerator({ eventName: "Ponds Alerts - History Btn" });
      this.isHistoryAlertActive = !this.isHistoryAlertActive;
      this.$emit("history", this.isHistoryAlertActive);
    },
    handelReload() {
      this.mixPanelEventGenerator({ eventName: "Ponds Alerts - Refresh" });
      this.$emit('refresh')
    },
    handleChangeInSelectedPonds(selectedPonds) {
      const filter = {
        filter_type: "POND",
        filters: selectedPonds.length > 0 ? selectedPonds : ["GATEWAY_GROUP_ID"]
      };
      this.changeAlertsFilterSelection(filter);
      this.mixPanelEventGenerator({ eventName: "Ponds Alerts - Filter" });
    },
    renderAlertIcon(alertCode) {
      return errorAlertsUtils.renderDeviceAlertIcon(alertCode);
    },
    renderAlertName(data) {
      return this.$t(errorAlertsUtils.renderAlertName(data));
    },
    handleTableDataDownload(downloadtype) {
      this.mixPanelEventGenerator({ eventName: "Ponds Alerts - DownLoad" });
      if (downloadtype === "Comn_download_as_pdf") {
        this.pdfdownload();
      } else {
        this.xceldownload();
      }
    },

    xceldownload() {
      this.exp__Download(
        this.$refs.table.$refs.dataTables.sortedData,
        this.downloadColumns,
        "excel"
      );
    },
    pdfdownload() {
      this.exp__Download(
        this.$refs.table.$refs.dataTables.sortedData,
        this.downloadColumns,
        "pdf"
      );
    }
  }
};
</script>
<style lang="scss">
.el-dialog__body {
  font-size: 13px !important;
  padding: 12px;
  .el-table td,
  .el-table th {
    padding: 0px;
  }
}
.pond-alerts-table {
  overflow: auto;
  .action-icon {
    cursor: pointer;
    color: black;
    opacity: 0.4;
    font-weight: 500;
    @include icon-size(icon-font);
    &:hover {
      opacity: 1;
    }
  }
  .er-button.er-button--refresh.el-button--text.er-button--icon
    span.material-icons-round {
    @include icon-size(icon-font);
  }
  .alerts-table-options {
    display: flex;
    .er-dropdown {
      margin-right: 12px;
      cursor: pointer;
      .el-icon-download {
        font-size: 20px;
        color: #606266;
        opacity: 0.4;
      }
      .el-icon-download:hover {
        color: #606266;
        opacity: 1;
      }
    }
  }
  .pond__header {
    display: flex;
    justify-content: space-between;
    padding: 8px;
  }
  .el-row--flex {
    padding: 0.5rem;
  }
  &__header {
    font-weight: $font_weight_header;
    color: #0a2463;
    opacity: 0.9;
    @include responsiveProperty(font-size, 1rem, 1.29em, 1.5em);
  }
  .er-data-tables .white-header-table .el-table .el-table__header {
    background-color: transparent;
    border-bottom: 1px solid #ebeef5;
  }
  .el-table {
    border-radius: 0px !important;
    box-shadow: none !important;
    .cell {
      text-align: center;
      font-weight: 500;
    }
  }
  .el-card__body {
    padding: 0px;
  }
  .el-table thead {
    color: #0a2463;
    line-height: 20px;
  }
  .el-table__header th > .cell {
    // height: 30px;
    letter-spacing: 0.3px;
    // text-align: center;
    background-color: white;
    font-weight: 600;
  }
  width: 100%;
  .el-table td {
    padding: 0px;
    .cell {
      line-height: 1;
    }
  }
  .el-dialog__body {
    font-size: 13px !important;
    padding: 12px;
    .el-table td,
    .el-table th {
      padding: 0px;
    }
  }
  .el-table__row {
    .cell {
      img {
        @include responsiveProperty(width, 2em, 2em, 1.8em);
        // margin-top: 5px;
        margin: 0px;
        padding: 0px;
        display: flex;
      }
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    opacity: 0.2;
    // margin: 0 auto;
    // display: inline-block;
    background-color: #6c7b8a;
  }
  @include responsiveProperty(--height-gutter, 129px, 131px, 150px);
  .history-button {
    display: flex;
    align-items: center;
    border: 1px solid #606266;
    cursor: pointer;
    font-weight: 500;
    @include responsiveProperty(font-size, 1rem, 1.29em, 1.5em);
    span {
      margin: 0 8px;
    }
  }
  .history-button-active {
    display: flex;
    align-items: center;
    color: #fff;
    background-color: #0a2463;
    opacity: 1;
    cursor: pointer;
    font-weight: 500;
    @include responsiveProperty(font-size, 1rem, 1.29em, 1.5em);
    span {
      margin: 0 8px;
    }
    .el-button--text {
      i {
        color: #fff !important;
      }
    }
  }
}
.download_dropdown {
  color: #606266;
  cursor: pointer;
  .el-dropdown-menu__item {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
}

.pond-alerts-table-el-tooltip__popper {
  border: 1px solid #606266 !important;
  color: #606266 !important;
}

.pond-alerts-table-el-tooltip__popper.is-light[x-placement^="bottom"]
  .popper__arrow {
  border-bottom-color: #606266 !important;
  top: -7px;
}
</style>
